.import-section {
	font-weight: 300;
	font-size: 14px;
	color: #84848d;

	display: flex;
	flex-direction: column;
	/* align-items: center; */
	padding: 0px;
	margin-left: 10px;
	margin-top: 10px;
	gap: 1px;
}

.error-message {
	margin-top: 5px;
	margin-left: 5px;
	color: red;
	font-weight: 300;
	font-size: 0.8rem;
}

.dp-head-container {
	display: flex;
	/* align-items: center; */
	justify-content: space-between;
}

.connection {
	display: flex;
	align-items: center;
}

.logo {
	margin-right: 10px;
}

.import-connection {
	margin-left: auto;
	display: flex;
	align-items: center;
}

.add-filter-btn {
	background-color: #ee8245;
	border-radius: 4px;
	color: white;
	width: 100% !important;
}

.import-connection-btn {
	background-color: #ee8245;
	border-radius: 4px;
	color: white;
	min-width: 120px !important;
}

.import-cancel-btn {
	background-color: #ff5733;
	border-radius: 4px;
	color: white;
	min-width: 120px !important;
}

.lookup-ai {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.lookup-ai-tooltip {
	margin-left: 2px;
}

.lookup-ai-btn {
	margin-top: 10px;
	background-color: #3a3a3d;
	border-radius: 4px;
	color: white;
	width: 100% !important;
}

.lookup-ai-btn:hover {
	background-color: #3a3a3d !important;
	color: white;
	border: 1px solid #ffffff !important;
}

.line-break {
	/* color: #E6E7E8 !important; */
	margin-top: 24px !important;
	margin-bottom: 24px !important;
}

.add-filter-btn:hover {
	background-color: #ee8245 !important;
	color: white !important;
	border: 1px solid #ffffff !important;
}

.import-connection-btn:hover {
	background-color: #ee8245 !important;
	color: white !important;
	border: 1px solid #ffffff !important;
}

.import-cancel-btn:hover {
	background-color: #ff5733 !important;
	color: white !important;
	border: 1px solid #ffffff !important;
}

.import-connection-btn:disabled {
	background-color: #f0844679 !important;
	color: white !important;
}

.import-cancel-btn:disabled {
	background-color: #ff5733 !important;
	color: white !important;
}

.connection-logo {
	width: 32px;
	height: 32px;
	/* margin-right: 5px; */
	/* margin-top: 10px; */
	/* align-self: baseline; */
}

.fields-head {
	white-space: nowrap;
	font-style: italic;
	font-size: 12px;
	font-weight: 200;
	padding: 10px;
}

.filters-head {
	font-size: 12px;
	font-weight: 200;
	padding: 10px;
}

.main {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 15px;
}

.dp-right-container {
	overflow-y: auto !important;
	border-top: 1px solid #2e2e2e !important;
	border-bottom: 1px solid #2e2e2e !important;
	border-left: 1px solid #95928e !important;
	border-right: 1px solid #2e2e2e;
	flex-grow: 0;
	flex-shrink: 0;
}

.dp-right-container-old {
	background-color: rgb(255, 250, 243) !important;
	height: 576px;
	width: 322px;
	overflow-y: auto !important;
	border: 1px solid #e6e7e8 !important;
}

.dp-left-container {
	background-color: rgb(255, 250, 243) !important;
	height: 576px;
	width: 675px;
	overflow: hidden !important;
	border: 1px solid #e6e7e8 !important;
}

th.ant-table-cell.ant-table-cell-ellipsis {
	background-color: #ffecd1 !important;
	color: #ee8245 !important;
}

.table {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 576px;
	text-align: center;
}

:where(.css-mxhywb).ant-card .ant-card-body {
	padding: 4px !important;
}

.site-tree-search-value {
	color: #ee8245;
}

.toggleLookupAi {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.toggleLookupAiSwitch {
	background-color: #3a3a3d;
	border-radius: 4px;
	color: white;
	margin-right: 10px;
}

.toggleLookupAiSwitch:hover {
	background-color: #3a3a3d;
	color: white !important;
	border: 1px solid #ffffff !important;
}

.hubspot-or {
	font-size: 12px;
	font-weight: 200;
	padding: 10px;
}

.hubspot-and {
	font-size: 10px;
	font-weight: 100;
	padding: 10px;
}

.filter-tooltip {
	margin-bottom: 10px;
	margin-left: 2px;
}

.error-msg-lookup-ai-link {
	color: inherit;
}

.linkedin-metrics-info {
	@apply text-xs mb-2 flex justify-between;
}

.pagination-btn {
	background: #ff8c00;
	border: 1px solid #ff8c00;
	color: #fff;
	width: 100%;
	border-radius: 4px;
	font-weight: 500;
}

.pagination-btn:hover {
	background: #ff8c00 !important;
	color: white !important;
}

.ant-input:focus {
	border-color: #008f18;
	outline: 0;
	-webkit-box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
	box-shadow: 0 0 0 2px rgba(87, 168, 233, 0.2);
}

.clear-conf-button:hover {
	background-color: rgb(168 44 0 / 1);
	border: none;
	color: white;
}
