.divider {
	margin: 0;
}

chargebee-auth-body {
	margin: 20px;
	padding: 20px;
	display: flex;
}

.chargebee-auth-sec-top {
	background: #ffffff;
	padding: 24px;
	display: flex;
	flex-direction: column;
}

.chargebee-auth-sec-connection-wrapper {
	display: flex;
	padding: 0% 20%;
	justify-content: center;
}

.chargebee-auth-sec-connection-logos {
	justify-content: center;
	vertical-align: center;
	margin: 3px;
}

.chargebee-auth-sec-connection-sync {
	justify-content: center;
	vertical-align: center;
	margin: 20px 20px;
}

.chargebee-auth-sec-connection-status-msg {
	justify-content: center;
	vertical-align: center;
	margin: 5px 20px 4px 20px;
	font-weight: 500;
	font-size: 0.9rem;
	text-align: center;
}

.chargebee-auth-sec-main-store-name-head {
	margin: 10px 0px 10px 0px !important;
	display: flex;
	align-items: center;
}

.chargebee-auth-sec-main-store-name-head-sub {
	margin: 5px 0px 5px 0px !important;
}

.chargebee-auth-sec-main {
	padding: 5px 2px;
	/* margin-bottom: 70px; */
}

.chargebee-auth-sec-main-heading {
	font-size: 1.1rem;
	font-weight: 600;
	line-height: 1.2;
	margin-top: 12px;
	margin-bottom: 24px;
}

.chargebee-auth-sec-main-description {
	font-size: 0.9rem;
	font-weight: 300;
	line-height: 1.5;
	opacity: 0.7;
	margin-top: 12px;
	margin-bottom: 10px;
}

.chargebee-auth-sec-main-store-name-input {
	margin-top: 14px;
	margin-bottom: 10px;
	/* margin-right: 10px; */
}

.chargebee-auth-sec-main-store-name-input-sub {
	margin-top: 7px;
	margin-bottom: 10px;
	/* margin-right: 10px; */
}

.chargebee-auth-sec-main-store-name-head-sub-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.chargebee-auth-sec-main-store-name-input-end {
	margin-top: 10px;
	margin-bottom: 10px;
	margin-right: 10px !important;
}

.db-input {
	margin-bottom: 15px;
	margin-top: 15px;
	border-radius: 0;
}

.chargebee-btn {
	background: #ff8c00;
	border: 1px solid #ff8c00;
	color: #fff;
	width: 100%;
	height: 38px;
	border-radius: 4px;
	font-size: 1rem;
	font-weight: 500;
	margin-bottom: 10px;
}

.chargebee-btn:hover {
	background: #ff8c00 !important;
}

.find-api-key-head {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	font-size: 0.8rem;
}

.head-para {
	flex: 0.8;
	font-weight: 300;
	line-height: 1.5;
}

.head-need-help a {
	color: #ff8c00;
	text-decoration: none;
}

.steps-img-div {
	text-align: center;
}

.steps-text {
	font-style: normal;
	font-weight: 200;
	font-size: 0.8rem;
}

.step-highlight {
	font-weight: 400;
}

.error-msg {
	margin-top: 0px;
	color: red;
	font-weight: 300;
	font-size: 0.8rem;
}

.success-msg {
	margin-top: 0px;
	font-weight: 300;
	font-size: 0.8rem;
}

.success-msg-lookup-ai {
	margin-top: 10px;
	font-weight: 300;
	font-size: 0.8rem;
}

.error-msg-lookup-ai {
	margin-top: 10px;
	color: red;
	font-weight: 300;
	font-size: 0.8rem;
}

.step-img {
	height: 156px;
	width: auto;
}

.chargebee-table-column-selection {
	margin-bottom: 0;
	margin-right: 10px;
}

.chargebee-import-select-info {
	background-color: #ffecd1;
	border-radius: 7px;
	padding: 12px;
	margin-right: 12px;
	z-index: 2;
	margin-bottom: 10px;
}

.chargebee-import-select-head {
	margin-top: 5px;
	font-weight: 500;
	margin-bottom: 2px;
	color: #3a3a3d;
}

.chargebee-import-select-para {
	font-size: 0.9rem;
	font-weight: 300;
	margin-top: 0px;
	margin-bottom: 5px;
}

.datepicker {
	margin-bottom: 36px;
}

/* picker-dropdown .ant-picker-time-panel {
  max-width: 280px !important;
} */

.custom-datepicker {
	width: 209px;
	margin-bottom: 25px;
}

.chargeebee-update-schema {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.8rem;
}

.tab-container {
	display: flex;
	align-items: center;
	gap: 3px;
}

.tab-container > span {
	font-size: small;
}

.join-collapse-section {
	font-weight: bold;
}

.join-table-section {
	border-radius: 2px;
	border: 1px solid #e6e7e8;
	background: #fff;
	padding: 4px 8px;
}

.join-section-action-buttons {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}

.sql-data-preview-table-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 100%;
	width: 100%;
}

.sql-filter-section-new {
	background: #fff6e9;
	padding: 8px 4px;
	border-bottom: 1px solid #95928e;
	width: 100%;
	align-self: flex-start;
}

.sql-filter-section-new-buttons {
	color: #ee8245;
	background: transparent;
	border-radius: 14.3px;
	border: none;
	box-shadow: none;
}

.sql-filter-section-new-buttons:hover {
	background: #ffecd1;
}

.sql-filter-section-new-buttons-content {
	display: flex !important;
	align-items: center;
	color: #2e2e2e;
}

.sql-filter-section {
	background: #fffdf9;
	padding: 5px;
	border-bottom: 1px solid #e8e8e8;
	width: 100%;
	height: 6%;
	align-self: flex-start;
}

.sql-filter-section-buttons {
	background: #fefaf4;
	color: #ee8245;
	border-radius: 14.3px;
}

.sql-filter-section-buttons-content {
	display: flex !important;
	align-items: center;
}

.sql-table-with-filter-section {
	height: 83%;
	width: 100%;
}

.sort-section-input {
	display: flex;
	border-radius: 2px;
	border: 0.5px solid #ff9500;
	background: #fff4e5;
	padding: 5px;
	margin-bottom: 6px;
}

.filter-section-input {
	border-radius: 2px;
	border: 0.5px solid #ff9500;
	background: #fff4e5;
	padding: 5px;
	margin-bottom: 6px;
}

.sort-section-action-button-section {
	align-self: flex-end;
	margin-top: 5px;
}

.data-preview-table-wrapper {
	font-weight: 400;
	font-size: 14px;
	border-top: 1px solid #2e2e2e !important;
	border-bottom: 1px solid #2e2e2e !important;
	border-left: 1px solid #2e2e2e !important;
	background: #fffdf9;
	height: 500px;
	flex-grow: 1;
	flex-shrink: 1;
	width: auto;
}

.data-preview-table-wrapper-old {
	font-weight: 400;
	font-size: 14px;
	border: 1px solid #e8e8e8;
	background: #fffdf9;
	height: 89%;
	width: 100%;
}

.filter {
	transition: opacity 0.3s ease, transform 0.3s ease;
	opacity: 1; /* Filters are fully visible by default */
}

.filter.hidden {
	opacity: 0;
	transform: scale(0.95); /* Slightly shrink the element */
	height: 0; /* Reduce height to 0 */
	overflow: hidden; /* Prevent content from spilling out during transition */
	margin: 0; /* Remove margin during transition */
	padding: 0; /* Remove padding during transition */
	border: none; /* Hide border during transition */
}
