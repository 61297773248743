.initialAccordian {
	clip-path: polygon(0 0, 100% 0%, 100% 0, 0 0);
	transition: all;
	transition-duration: 0.1s;
	transition-timing-function: ease-in-out;
}

.initialAccordian.active {
	clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 100%);
}
