@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://storage.googleapis.com/plugin-assets/fonts/Satoshi_Complete/Fonts/WEB/css/satoshi.css");

*::-webkit-scrollbar {
	display: none;
	scrollbar-width: none;
}

* {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.ant-drawer-body {
	padding: 20px !important;
}

* {
	@apply font-satoshi;
}

.ant-tag {
	@apply border-none rounded-full font-satoshi;
}

.ant-tag-gold {
	@apply bg-stackit-danger-secondary text-stackit-danger-primary;
}

.ant-tag-green {
	@apply bg-stackit-success-secondary text-stackit-success-primary;
}

.ant-tabs-nav {
	margin: 0 0 !important;
}

.ant-tabs-tab {
	padding: 5px 0 !important;
}

@layer base {
	input[type="number"]::-webkit-inner-spin-button,
	input[type="number"]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
}

@layer utilities {
	/* Hide scrollbar for Chrome, Safari and Opera */
	.no-scrollbar::-webkit-scrollbar {
		display: none;
	}
	/* Hide scrollbar for IE, Edge and Firefox */
	.no-scrollbar {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
}

::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}
::-webkit-scrollbar-thumb {
	border-radius: 8px;
	background: #d7d5d2;
}

::-webkit-scrollbar-track {
	background: transparent;
}

.checkbox-container:after {
	color: white;
	left: 8px;
	bottom: 5px;
	width: 6px;
	height: 12px;
	border: solid white;
	border-width: 0 4px 4px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

/* Change the default border color */
.ant-select-selector {
	border-color: #95928e !important; /* Change #blue to your desired border color */
}

/* Change the border color when the Select is focused */
.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-open .ant-select-selector {
	border-color: #2e2e2e !important; /* Change #green to your desired active border color */
}



/* Change the border color when the inputs are focused */
.ant-input:focus,
.ant-picker:focus .ant-picker-input input,
.ant-input-number:focus {
	border-color: #2e2e2e !important; /* Change #green to your desired active border color */
}

/* Customizing the focus border color specifically for the DatePicker to cover all focus scenarios */
.ant-picker-focused,
.ant-picker-input:focus-within .ant-picker-input {
	border-color: #2e2e2e !important; /* Same as above for consistency */
}

.ant-input-search-button {
	border: 1px solid #95928e !important; /* Change #blue to your desired border color */
}

/* Change the default border color of the search input */
.ant-input-affix-wrapper {
	border: 1px solid #95928e !important; /* Change #blue to your desired border color */
}

/* Change the border color when the search input is focused */
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
	border-color: #2e2e2e !important; /* Change #green to your desired active border color */
}

.ant-cascader-menu-item-selected {
	background-color: #2e2e2e !important; /* Tomato color; change this to your desired color */
}

.ant-cascader-menus .ant-cascader-menu-item-active,
.ant-cascader-menus .ant-cascader-menu-item-selected {
	background-color: #ffe7d7 !important; /* Tomato color; change this to your desired color */
}

.ant-select-item-option-selected {
	background-color: #ffe7d7 !important; /* Change this to your desired background color */
}

.ant-cascader-menu {
	min-height: 300px; /* or whatever height you prefer */
}

.ant-drawer-content-wrapper {
	max-height: 100%;
	overflow-y: auto !important;
}

/* Remove the "f" prefix, assuming it is added by a span with a specific class */
.cm-autocompleteIcon {
	display: none; /* This hides the icon */
}

/* Add padding and modern styling to the autocomplete suggestions */
.cm-tooltip.cm-tooltip-autocomplete {
	font-family: "Arial", sans-serif; /* Choose a modern font */
	font-size: 0.875rem; /* 14px if the root font-size is 16px */
	background-color: #fff; /* White background */
	color: #333; /* Dark text for readability */
	border: 1px solid #d1d5db; /* Light gray border */
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
	border-radius: 0.375rem; /* 6px for a rounded corner */
}

/* Style individual autocomplete items */
.cm-tooltip.cm-tooltip-autocomplete li {
	padding: 0.5rem 1rem; /* 8px top/bottom and 16px left/right padding */
	line-height: 1.5; /* A comfortable line height */
	cursor: pointer; /* Pointer cursor on hover */
}

/* Style the selected or highlighted item in the autocomplete */
.cm-tooltip.cm-tooltip-autocomplete li[aria-selected="true"],
.cm-tooltip.cm-tooltip-autocomplete li:hover {
	background-color: #ebf4ff; /* Light blue background for selection/hover */
	color: #1a202c; /* Slightly darker text color for contrast */
}

[type="search"]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	appearance: none;
	height: 10px;
	width: 10px;
	background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
	background-size: 10px 10px;
	cursor: pointer;
}
